@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Coda&display=swap');

/* @font-face {
  font-family: 'Knicknack';
  src: url('./assets//fonts/Knicknack-Medium.eot'), url('./assets/fonts/Knicknack-Medium.ttf') format('truetype'), url('./assets/fonts/Knicknack-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

.font-coda{
  font-family: 'Coda', cursive;
}

.font-itim{
  font-family: 'Itim', cursive;
}

.font-custom {
  /* font-family: 'Knicknack', Helvetica,Arial,Lucida,sans-serif; */
  font-family: 'Coda', cursive;
  letter-spacing: 0.06em;
  
}

*{
  @apply font-coda;
  font-weight: lighter;
}
/* Custom Tailwind classes */
@layer components{
  /* Basic */
  .btn-wide{
    @apply px-20 py-6 bg-purple-400 hover:bg-purple-300 rounded-full
  }
  .btn-success{
    @apply bg-green-300 text-green-900 hover:bg-green-400;
  }
  .btn-primary{
    @apply font-semibold text-lg bg-blue-500 hover:bg-blue-600 text-white py-3 px-12 rounded-full font-sans cursor-pointer
  }
  .btn-delete {
    @apply bg-red-500 hover:bg-red-400 px-2 py-1 text-white rounded-md mr-8
  }
  .btn-delete::after {
    @apply border-none
  }
  .btn-menu{
    @apply font-semibold px-4 py-2 hover:bg-gray-700 hover:text-white rounded-lg block
  }
  .flex-row-center{
    @apply flex flex-row justify-center items-center
  }
  .flex-col-center{
    @apply flex flex-col justify-center items-center
  }

  .text-input {
    @apply mt-1 block w-full border-none bg-gray-100 h-11 rounded-xl shadow-lg focus:bg-blue-100 focus:ring-0 px-5
  }
 /* Text */
  .headline { 
    @apply text-center text-5xl font-extrabold font-custom
  }
  .heading{

  }
  .subheading{

  }
  .paragraph{
    @apply font-sans tracking-wide
  }

input[type="file"] {
opacity: 0;
  z-index: 1;
}

.upload:active {
box-shadow: 0px 5px 13px #cce2ff;
  -webkit-box-shadow: 0px 5px 13px #cce2ff;
  -moz-box-shadow: 0px 5px 13px #cce2ff;
  -o-box-shadow: 0px 5px 13px #cce2ff;
  -ms-box-shadow: 0px 5px 13px #cce2ff;
}

/* Custom slider  */
.slider {
  -webkit-appearance: none;
  /* width: 50%; */
  height: 12px;
  border-radius: 5px;  
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #4183c4;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4183c4;
  cursor: pointer;
}



/* Media queries */
  @screen lg {
    .btn { 
      @apply px-8
    }
  }
}
