@import url('https://fonts.googleapis.com/css2?family=Black+And+White+Picture&family=Bonbon&family=Concert+One&family=Inconsolata&family=Loved+by+the+King&family=Noto+Serif:ital,wght@1,700&family=Special+Elite&display=swap');
.blue-button{
  background: linear-gradient(-45deg, #3f00b5, #9f69fe, #27c8b7, #3f00b5);
 background-size: 800% 400%;
 padding: 1em 2em;
 display: inline-block;
 border: none;
 border-radius: 10px;
 font-size: 17px;
 font-weight: 700;
 color: white;
 transition: all .5s ease-in-out;
 animation: gradient 10s infinite cubic-bezier(.62, .28, .23, .99) both;
}

* {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.tab-content {
  max-height: 0;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
}
/* :checked - resize to full height */
.tab input:checked ~ .tab-content {
  max-height: 100vh;
}

@keyframes gradient {
  0% {
   background-position: 0% 50%;
  }
 
  50% {
   background-position: 100% 50%;
  }
 
  100% {
   background-position: 0% 50%;
  }
 }



 /* Toggle A */
.checkbox:checked ~ .dot {
  transform: translateX(100%);
  /* background-color: #1c04f7; */
}


.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.center p{
  font-size: xx-large;
}

.template-wrapper{
  padding: 1rem 2rem;
}
.template-wrapper div{
  padding: 1rem 2rem;
}

.template-1 {
  background: rgba(144, 19, 254, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.template-2 {
  background: rgba(65, 117, 5, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.template-3 {
  background: rgba(244, 225, 8, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.template-4 {
  background: #bd10e0;
  padding: 20px;
  opacity: 0.8;
}

.template-4 div {
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background: #bd10e0;
  -webkit-box-shadow: 12px 12px 24px #a10ebe, -12px -12px 24px #d912ff;
  box-shadow: 12px 12px 24px #a10ebe, -12px -12px 24px #d912ff;
}

.template-5 {
  background: #4a90e2;
  padding: 20px;
  opacity: 0.8;
}
.template-5 div {
  -webkit-border-radius: 50px;
  border-radius: 50px;
  background: #4a90e2;
  -webkit-box-shadow: 14px 14px 17px #3f7ac0, -14px -14px 17px #55a6ff;
  box-shadow: 14px 14px 17px #3f7ac0, -14px -14px 17px #55a6ff;
}




/* FONTS */

.Coda{
  font-family: 'Coda', cursive;
}

.Lobster{
  font-family: 'Lobster', cursive;
}

.Bonbon{
  font-family: 'Bonbon', cursive;
}

.BlackAndWhitePicture {
  font-family: 'Black And White Picture', cursive;

}

.ConcertOne{
  font-family: 'Concert One', cursive;
}
.LovedbytheKing{
  font-family: 'Loved by the King', cursive;

}
.SpecialElite{
  font-family: 'Special Elite', cursive;
}

.Inconsolata{
  font-family: 'Inconsolata', cursive;
}

.NotoSerif{
  font-family: 'Noto+Serif', cursive;
}
.Small{
  font-size: 0.5rem;
}
.Medium{
  font-size: 0.75rem;
}
.Large{
  font-size: 1.25rem;

}
.ExtraLarge{
  font-size: 1.5rem;
}


.transform-control{
  position: absolute;
  bottom: 30px;
  right: 26%;
} 
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.scrollbar-hide::-webkit-scrollbar{
  display: none;

}

.current-location{
  position: relative;
  bottom: 50px;
  left: 20px;
}

.loader-container{
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh;
  width: 100vw; */
  top: 0;
  bottom : 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 99999;
}


.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 